<template>
  <div style="overflow: hidden;">
    <iframe :src="termsUrl"
            width="100%"
            style="height: 95vh"
            frameborder="0"
            allowfullscreen>
      <p>Your browser does not support iframes.</p>
    </iframe>
  </div>
</template>

<script>
  export default {
    name : 'TermsOfUse',
    head : {
      title : {
        inner      : 'Terms Of Use',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        termsUrl : "https://app.termly.io/document/terms-of-use-for-website/" +
          "84bd70ac-d9e6-49fd-a472-32e12c7b0e87",
      }
    },
  };
</script>